import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

import SEO from '../components/seo';

import { Item, Menu } from '../styles/styles.js';

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Posto e Conveniência União Cardápio"
        keywords={[`café`, `pão de queijo`, `assados`, `fritos`, `doces`]}
      />
      <Menu className="menu">
        <Slide left cascade>
          <h1 className="menu-title">Café da manhã</h1>
        </Slide>

        <Fade left>
          <div className="item-image">
            <Img
              fluid={data.coffee.childImageSharp.fluid}
              alt="Café da manhã"
            />
          </div>
        </Fade>

        <Slide left>
          <h2 className="menu-subtitle">Café</h2>
        </Slide>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Café Expresso</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Café Expresso curto</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">3,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Café com Leite</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Cappuccino</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Mokaccino dois Frades</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Cappuccino Alpino</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Achocolatado Alpino</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Achocolatado Nescau</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Slide left>
          <h3 className="menu-subtitle">
            Chipa, Pão de Queijo, Salgados, Lanches..
          </h3>
        </Slide>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Chipa de Queijo</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">4,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Pão de Queijo Minas</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">2,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Pão de Queijo</h4>

            <div className="item-price-wrapper">
              <p className="item-price" />
            </div>
          </div>

          <p className="item-desc">Grande 4,00 / Médio 1,50</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Pão com Margarina</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">4,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Biscoito Parmesão</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">2,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Mini Pizza</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">15,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Pizza (Pedaço)</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Cachorro Quente</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Misto Quente</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">10,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Misto Quente completo com Ovo</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">15,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Bauru de Forno</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">10,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Mini Burguer</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">16,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Sanduíche Paulistinha</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">15,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Filé bovino acebolado, catupiry e queijo mussarela.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Sanduíche Natural</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">10,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Sanduíche Natural c/ Peito de Peru</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Omelete</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">15,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Salgados Assados (Unidade)</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">10,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Salgados Fritos (Unidade)</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Pastel</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">15,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Sabores: Carne, carne e queijo, frango, presunto e queijo, frango e
            catupiry.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Galeto de Frango</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>
        </Item>

        {/* <Slide left>
          <h2 className="menu-subtitle">Doces</h2>
        </Slide>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Arroz Doce (pote)</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Bolo (Pedaço)</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Brigadeiro (Unidade)</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Canjica (pote)</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Gelatina (pote)</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Mousse (pote)</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Pavê (pote)</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Pudim Pedaço</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item> */}
      </Menu>
    </>
  );
};

export const query = graphql`
  query {
    coffee: file(relativePath: { eq: "coffee.png" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default IndexPage;
