import styled from 'styled-components';

export const Menu = styled.main`
  padding: 15px;
  max-width: 600px;
  margin: 0 auto;

  .menu-title {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .menu-subtitle {
    margin-top: 25px;
    margin-bottom: 35px;
    border-bottom: 3px solid #e5b502;
    padding-bottom: 3px;
  }

  .item-image {
    max-width: 350px;
    margin: 0 auto 35px;
  }

  .item-image-bebidas {
    max-width: 275px;
    margin: 0 auto 35px;
  }
`;

export const Item = styled.div`
  margin-bottom: 30px;

  h4 {
    font-family: 'Roboto', 'sans-serif';
  }

  .item-separator {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item-title {
    margin-right: 15px;
  }

  .item-price {
    color: #00803d;
  }

  .item-desc {
    color: #777;
  }
`;
